<template>
  <div class="p-pegas">
    <div
      class="card-table"
      :class="mode ? 'card__tableday' : 'card__tablenight'"
    >
      <div class="card-table-body ifon-add-title-block">
        <el-row :gutter="20">
          <el-col :sm="16" :xs="24">
            <div class="caz-blocks-sarcho-title">
              <div
                class="content-title d-flex align-center mr-2"
                :class="mode ? 'content__titleday' : 'content__titlenight'"
              >
                {{ $t("message.additional_work") }}
              </div>
              <div class="block-sarche">
                <div class="header__search d-flex">
                  <crm-input
                    :size="'small'"
                    :className="'w100'"
                    v-model="filterForm.search"
                    :class="mode ? 'input__day' : 'input__night'"
                    :icon="'el-icon-search'"
                  ></crm-input>
                  <div class="mx-3">
                    <select-branch
                      size="small"
                      :class="mode ? 'filter__day' : 'filter__night'"
                      v-model="filterForm.branch_id"
                    >
                    </select-branch>
                  </div>
                  <div class="mx-3">
                    <select-staff
                      v-model="filterForm.staff_id"
                      :class="mode ? 'input__day' : 'input__night'"
                      :id="filterForm.staff_id"
                    ></select-staff>
                  </div>
                  <div class="mx-3 mr0__mobile">
                    <el-date-picker
                      v-model="filterForm.work_date_time"
                      :class="mode ? 'input__day' : 'input__night'"
                      type="date"
                      :placeholder="$t('message.datas')"
                      size="small"
                      :format="'dd.MM.yyyy'"
                      :value-format="'dd.MM.yyyy'"
                    >
                    </el-date-picker>
                  </div>
                  <!-- <div class="mx-3">
                    <el-date-picker
                      v-model="filterForm.to_date"
                      type="date"
                      :placeholder="$t('message.to_data')"
                      size="small"
                      :format="'dd.MM.yyyy'"
                      :value-format="'dd.MM.yyyy'"
                    >
                    </el-date-picker>
                  </div> -->
                </div>
              </div>
            </div>
          </el-col>

          <el-col :sm="8" :xs="24" class="flex-style text-right mbtop5">
            <crm-create-and-column-settings
              :can_create="false"
              :permission="$options.name"
              :class="mode ? 'button__settingsday' : 'button__settingsnight'"
              @c-create="drawerCreate = true"
              :columns="columns"
              @c-change="updateColumn"
            >
            </crm-create-and-column-settings>
          </el-col>
        </el-row>
      </div>
      <!-- end ifon-add-title-block -->

      <div class="card-table-header table-crm-smart">
        <table
          class="table-my-code table-bordered"
          v-loading="loadingData"
          :class="mode ? 'table__myday' : 'table__mynight'"
        >
          <thead>
            <tr>
              <th class="w50p" v-if="columns.id.show">
                {{ columns.id.title }}
              </th>
              <th v-if="columns.performance.show">
                {{ columns.performance.title }}
              </th>
              <th v-if="columns.reason.show">
                {{ columns.reason.title }}
              </th>
              <th v-if="columns.staff_id.show">
                {{ columns.staff_id.title }}
              </th>
              <th v-if="columns.date.show">
                {{ columns.date.title }}
              </th>
              <th v-if="columns.count.show">
                {{ columns.count.title }}
              </th>
              <th v-if="columns.header.show">
                {{ $t("message.details") }}
              </th>
              <th v-if="columns.status.show">
                {{ columns.status.title }}
              </th>
              <th v-if="columns.branch.show">
                {{ columns.branch.title }}
              </th>
            </tr>
          </thead>

          <transition-group name="flip-list" tag="tbody">
            <tr
              v-for="additional in list"
              :key="additional.id"
              class="cursor-pointer"
            >
              <td v-if="columns.id.show">{{ additional.id }}</td>

              <td v-if="columns.performance.show">
                {{
                  additional.performance_type == 1 ? "To'lovli" : "To'lovsiz"
                }}
              </td>

              <td v-if="columns.reason.show">
                {{ additional.reason ? additional.reason.name : "" }}
              </td>

              <td v-if="columns.staff_id.show">
                {{
                  additional.staff
                    ? additional.staff.name + " " + additional.staff.last_name
                    : ""
                }}
              </td>

              <td v-if="columns.date.show">
                {{ additional.work_date_time_from
                }}<br v-if="additional.work_date_time_from" />{{
                  additional.work_date_time
                }}
              </td>

              <td v-if="columns.count.show">
                {{ additional.count }}
              </td>

              <td v-if="columns.header.show">
                <el-button
                  @click="showStaffList(additional.id, additional.created_id)"
                  type="success"
                  size="medium"
                  class="remove-button"
                >
                  {{ $t("message.details") }}
                </el-button>
              </td>
              <td v-if="columns.status.show">
                <el-button
                  v-if="additional.status == 'waiting'"
                  type="warning"
                  size="medium"
                  class="remove-button"
                  >{{ $t("message.waiting") }}</el-button
                >
                <el-button
                  v-if="additional.status == 'accept'"
                  type="success"
                  size="medium"
                  class="remove-button"
                  >{{ $t("message.approved") }}</el-button
                >
                <el-button
                  v-if="additional.status == 'deny'"
                  type="danger"
                  size="medium"
                  class="remove-button"
                  >{{ $t("message.rejected") }}</el-button
                >
              </td>
              <td v-if="columns.branch.show">
                {{ additional.branch?additional.branch.name:'' }}
              </td>
            </tr>
          </transition-group>
        </table>
        <div class="my___pagination">
          <crm-pagination
            @c-change="updatePagination"
            :class="mode ? 'pagination__day' : 'pagination__night'"
            :pagination="pagination"
          ></crm-pagination>
        </div>
      </div>
    </div>
    <el-drawer
      title="I'm outer Drawer"
      :visible.sync="showUseerModal"
      size="30%"
      :with-header="false"
      ref="show-users"
      @opened="drawerOpened('show-users')"
    >
      <div>
        <show-users
          :staff="selected"
          @closeUserModal="closeUserModal"
          :child="child"
          ref="show-users"
        ></show-users>
      </div>
    </el-drawer>
  </div>
</template>
<script>
import list from "@/utils/mixins/list";
import { i18n } from "@/utils/i18n";
import showUsers from "./components/show-users.vue";

import { mapGetters, mapActions, mapState } from "vuex";
export default {
  name: "SkillController",
  mixins: [list],

  data() {
    return {
      child: {},
      showUseerModal: false,
      selected: null
    };
  },
  mounted() {
    this.updateList();
  },
  components: {
    showUsers
  },
  computed: {
    ...mapGetters({
      list: "additionalWork/list",
      columns: "additionalWork/columns",
      pagination: "additionalWork/pagination",
      statues: "additionalWork/statues",
      filter: "additionalWork/filter",
      sort: "additionalWork/sort",
      mode: "MODE"
    }),
    actions: function() {
      return ["edit", "delete"];
    }
  },
  methods: {
    ...mapActions({
      updateList: "additionalWork/index",
      setPagination: "additionalWork/setPagination",
      updateSort: "additionalWork/updateSort",
      updateFilter: "additionalWork/updateFilter",
      updateColumn: "additionalWork/updateColumn",
      updatePagination: "additionalWork/updatePagination",
      refreshData: "additionalWork/refreshData"
    }),

    showStaffList(work, staff_id) {
      this.showUseerModal = true;
      this.child.work_id = work;
      this.child.staff_id = staff_id;
    },
    closeUserModal(val) {
      this.showUseerModal = val;
    },
    drawerOpened(ref) {
      if (this.$refs[ref]) {
        if (_.isFunction(this.$refs[ref].opened)) {
          this.$refs[ref].opened(this.child);
        }
      }
    }
  }
};
</script>
